import React, { useState } from 'react'
import './App.css'
import { ThemeProvider } from '@mui/material/styles'
import { ApplicationForm, ThankYouPage } from './pages'
import { mainStyleTheme } from './theme'

function App() {
  const [status, setStatus] = useState({status: false, data: {}})
  return (
    <div className="App">
    <ThemeProvider theme={mainStyleTheme}>
    {status.status? <ThankYouPage status={status.data} setStatus={setStatus} /> : <ApplicationForm setStatus={setStatus} />}
      </ThemeProvider>
    </div>
  );
}

export default App;
