export const dateConvert = (datetime, convertToUTC) => {
    let date = new Date(datetime)
    let offset = date.getTimezoneOffset() * 60000
    let localedate = date.getTime()
    let localedatetime

    if (convertToUTC) {
        localedatetime = new Date(localedate + offset)
    } else {
        localedatetime = new Date(localedate - offset)
    }

    return localedatetime
}