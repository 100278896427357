import { dateConvert } from '../'

const actualDate = new Date()

export const dataPack = (values) => {
    let contentNotes = [
        {
            timestamp: dateConvert(actualDate),
            msg: `${values.content}`,
            user_email: values.email ? `${values.email}` : `${values.first_name} ${values.last_name}`,
            user_id: `1`,
            visibility: false,
            city: values.city,
            street: values.street,
            street_number: values.street_number,
            post_code: values.post_code,
            outside_sn: values.outside_sn,
            phone: values.phone,
            email: values.email ? values.email : "nie podano",
            first_name: values.first_name,
            last_name: values.last_name,
            confirmation: values.confirmation === true ? 'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu kontaktu.' : 'Brak zgody na kontakt.',
            confirmation2: values.confirmation2 === true ? 'Jakość naszej obsługi klienta jest dla nas bardzo ważna! Czy zgadzasz się na to, abyśmy skontaktowali się z Tobą po rozwiązaniu problemu, w celu uzyskania Twojej opinii na temat naszych usług? Jest to dla nas bardzo cenne źródło informacji, dzięki któremu będziemy mogli dalej doskonalić nasze usługi.' : 'Brak zgody na kontakt w celu badania satysfakcji klienta.'
        }
    ]

    return {
        subject: "Zgłoszenie - formularz ze strony www",
        content: JSON.stringify(contentNotes),
        note_type_id: 5,
        note_status_id: 1,
        parent_id: 0,
        root_id: 0,
        key: values.key,
        device_sn: values.device_sn,
        method_of_notification: `10`
    }
}