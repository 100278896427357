const serviceNoteClient = async (payload) => {

  const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/service_notes/add_customer_note`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  }).then(response => {
    console.log(response.status)
    if (response.status === 200){
      return response.json()
    } else {
      return response.status
    }
  })
  return res
}


export default serviceNoteClient