import React, { useState } from 'react'
import { Form } from "react-final-form"
import { TextField, Checkboxes } from 'mui-rff'
import { Container, Grid, Typography, Button, Dialog, DialogContent, IconButton, InputAdornment } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { serviceNoteClient, sendSmsPush, sendEmail, templateMsg } from '../../data'
import formatString from "format-string-by-pattern"
import { dateConvert, dataPack } from '../../functions'

import klucz from '../../img/klucz.svg'
import serial from '../../img/serial.svg'
import FAO from '../../img/FAO.svg'
import X from '../../img/x.svg'

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/
const paternFAI = /^fai$/i
const paternFAO = /^fao$/i
const paternEG = /^eg$/i
const paternAIO = /^aio$/i

const formatSn = (anyString) => {
    let device
    let upperCaseFormat = anyString.toUpperCase()
    if (upperCaseFormat !== undefined) {
        if (upperCaseFormat.slice(0, 1) === "E") {
            device = "XX-0000-00000000"
        } else {
            device = "XXX-0000-00000000"
        }
    }

    return formatString(device, upperCaseFormat)
}

const deviceNumber = (value) => {
    if (value !== undefined && value.length >= 3) {
        let testAIO = paternAIO.test(value.slice(0, 3))
        let testFAI = paternFAI.test(value.slice(0, 3))
        let testEG = paternEG.test(value.slice(0, 2))
        let testFAO = paternFAO.test(value.slice(0, 3))

        if (!testAIO && !testEG && !testFAI && !testFAO)
            return "Nieprawidłowy numer seryjny"

    }
}

const checkboxData = { label: 'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu kontaktu.', value: false }
const checkboxData2 = { label: "Jakość naszej obsługi klienta jest dla nas bardzo ważna! Czy zgadzasz się na to, abyśmy skontaktowali się z Tobą po rozwiązaniu problemu, w celu uzyskania Twojej opinii na temat naszych usług?  Jest to dla nas bardzo cenne źródło informacji, dzięki któremu będziemy mogli dalej doskonalić nasze usługi.", value: false }
const required = value => (value ? undefined : 'Pole wymagane')


const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('99-999', onlyNumbers)
}
const formatOnlyNumbersTel = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')
    let formatTel = ""

    if (onlyNumbers && onlyNumbers.slice(0, 2) !== "48") {
        formatTel = "48" + onlyNumbers
    } else {
        formatTel = onlyNumbers
    }

    return formatString('(+48)999-999-999', formatTel)
}
const minLength = min => value => value === undefined || value.length < min ? "Podany numer jest za krótki" : null
const maxLength = max => value => value === undefined || value.length > max ? "Podany numer jest za długi" : null
const textOnly = value => (value && textOnlyPattern.test(value) ? undefined : "Pole może zawierać tylko litery")
const formatTextOnly = (anyString) => {
    const onlyText = anyString.replace(/[^a-zA-Z]/g, '')
    return onlyText
}

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (anyString && !onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const requiredCheckbox = value => (value === true ? undefined : "Pole wymagane")

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const actualDate = new Date()
const errors = (status, setSendStatus) => {
    switch (status) {
        case 438:
            return setSendStatus({ status: status, msg: "Urządzenie nie istnieje. Sprawdź numer seryjny i klucz i spróbuj ponownie." })
        case 439:
            return setSendStatus({ status: status, msg: "Wystąpił błąd. Nie można teraz wysłać formularza." })
        default:
            return setSendStatus({ status: status, msg: "Wystąpił błąd. Nie można teraz wysłać formularza." })
    }
}

// const checkDeviceExist = value => {
//     console.log(value)
//     // (value === false ? undefined : "Urządzenie nie istnieje. Sprawdź numer seryjny i klucz i spróbuj ponownie.")
// }

const helpImg = (name) => {
    switch (name) {
        case "serial":
            return <img alt="Numer seryjny jednostki wewnętrznej" src={serial} />
        case "klucz":
            return <img alt="Klucz" src={klucz} />
        case "FAO":
            return <img alt="Numer seryjny jednostki zewnetrznej" src={FAO} />
        default:
            break;
    }
}

const ApplicationForm = ({ setStatus }) => {
    const [sendStatus, setSendStatus] = useState({ status: false, msg: "" })
    const [open, setOpen] = useState({ status: false, case: "" })

    const handleClickOpen = (e) => {
        setOpen({ status: true, case: e })
    }

    const handleClose = () => {
        setOpen({ ...open, status: false })
    }


    const sendServiceNote = (payload) => {
        serviceNoteClient(dataPack(payload))
            .then(response => {
                if (response.status === "ok") {
                    let data = response.data
                    sendEmail({ service_note_id: data.id, customer_id: data.customer_id, subject: "Euros Energy - Przyjęto zgłoszenie", body: templateMsg("email", { ...data, device_sn: payload.device_sn, key: payload.key }), email: payload.email })
                    sendEmail({ service_note_id: data.id, customer_id: data.customer_id, subject: "Nowe zgłoszenie - ze strony WWW", body: templateMsg("serwis", { ...data, device_sn: payload.device_sn, key: payload.key }), email: 'serwis@eurosenergy.com' })
                    sendSmsPush({ service_note_id: data.id, customer_id: data.customer_id, body: templateMsg("sms-push", { ...data, device_sn: payload.device_sn, key: payload.key }) }, payload.key, payload.phone, "Euros Energy - Przyjęto zgłoszenie")
                    setStatus({ status: true, data: { ...data, device_sn: payload.device_sn, key: payload.key } })
                } else {
                    errors(response, setSendStatus)
                }
            })
    }

    const onSubmit = async values => {
        sendServiceNote(values)
    }

    return (
        <React.Fragment>
            <Container style={{ margin: "30px auto" }} maxWidth="lg">
                <Grid container>
                    <Grid item xs={9}><Typography variant='h4' style={{ textAlign: 'left' }}>Formularz zgłoszenia serwisowego</Typography></Grid>
                    <Grid item xs={3}><Typography variant='h4' style={{ textAlign: 'right' }}>{actualDate.getDate() >= 10 ? actualDate.getDate() : `0${actualDate.getDate()}`}.{actualDate.getMonth() + 1 >= 10 ? actualDate.getMonth() + 1 : `0${actualDate.getMonth() + 1}`}.{actualDate.getFullYear()}</Typography></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Form
                            onSubmit={onSubmit}
                            render={({ handleSubmit, values }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}><Typography variant='body2' style={{ textAlign: 'left' }}>Zgłaszający</Typography></Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField label="Imię" name="first_name" fieldProps={{ validate: composeValidators(required), parse: string => formatTextOnly(string) }} />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField label="Nazwisko" name="last_name" fieldProps={{ validate: composeValidators(required), parse: string => formatTextOnly(string) }} />
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <TextField label="Telefon komórkowy" name="phone" fieldProps={{ validate: composeValidators(required, minLength(16), maxLength(16)), parse: string => formatOnlyNumbersTel(string) }} />
                                        </Grid>
                                        <Grid item sm={8} xs={12}>
                                            <TextField label="E-mail do potwierdzenia zgłoszenia" name="email" fieldProps={{ validate: composeValidators(formatEmail) }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}><Typography variant='body2' style={{ textAlign: 'left' }}>Identyfikacja urządzenia</Typography></Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField label="Numer seryjny jednostki wewnętrznej" name="device_sn"
                                                InputProps={{ endAdornment: <InputAdornment position="end"><HelpIcon onClick={(e) => handleClickOpen("serial")} style={{ color: "#b6b6b6", cursor: "pointer" }} /></InputAdornment> }}
                                                fieldProps={{ validate: composeValidators(required, deviceNumber), parse: string => formatSn(string) }} />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField label="Klucz urządzenia" name="key"
                                                InputProps={{ endAdornment: <InputAdornment position="end"><HelpIcon onClick={(e) => handleClickOpen("klucz")} style={{ color: "#b6b6b6", cursor: "pointer" }} /></InputAdornment> }}
                                                fieldProps={{ validate: composeValidators(required, minLength(20), maxLength(20)), parse: value => value && value.toUpperCase() }} />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            {values.device_sn && (paternFAI.test(values.device_sn.slice(0, 3)) || paternAIO.test(values.device_sn.slice(0, 3))) ? <TextField label="Numer seryjny jednostki zewnętrznej" name="outside_sn"
                                                InputProps={{ endAdornment: <InputAdornment position="end"><HelpIcon onClick={(e) => handleClickOpen("FAO")} style={{ color: "#b6b6b6", cursor: "pointer" }} /></InputAdornment> }}
                                                fieldProps={{ validate: composeValidators(deviceNumber), parse: string => formatSn(string) }} /> : null}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}><Typography variant='body2' style={{ textAlign: 'left' }}>Adres montażu urządzenia</Typography></Grid>
                                        <Grid item sm={8} xs={8}>
                                            <TextField label="Ulica" name="street" fieldProps={{ validate: composeValidators(required) }} />
                                        </Grid>
                                        <Grid item sm={4} xs={4}>
                                            <TextField label="Numer budynku" name="street_number" fieldProps={{ validate: composeValidators(required) }} />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <TextField label="Kod pocztowy" name="post_code" fieldProps={{ validate: composeValidators(required), parse: value => formatOnlyNumbers(value) }} />
                                        </Grid>
                                        <Grid item sm={8} xs={6}>
                                            <TextField label="Miejscowość" name="city" fieldProps={{ validate: composeValidators(required) }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}><Typography variant='body2' style={{ textAlign: 'left' }}>Opis problemu</Typography></Grid>
                                        <Grid item xs={12}>
                                            <TextField className='BaseTextarea' label="Treść zgłoszenia" name="content" fieldProps={{ validate: composeValidators(required) }} multiline minRows={10} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item sm={8} xs={12} style={{ textAlign: "left", paddingTop: "30px" }}>
                                            <Checkboxes
                                                name="confirmation"
                                                fieldProps={{ validate: composeValidators(requiredCheckbox) }}
                                                data={checkboxData}

                                            /><br /><br />
                                            <Checkboxes style={{ marginTop: "-35px" }}
                                                name="confirmation2"
                                                data={checkboxData2}
                                            />
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <Button variant="contained" color="warning" style={{ float: 'right' }} type="submit" >Wyślij zgłoszenie</Button>
                                        </Grid>
                                    </Grid>
                                    {sendStatus && sendStatus.status ? <Grid container spacing={2}>
                                        <Grid item xs={12} style={{ textAlign: "left" }}>
                                            <Typography variant='h7' style={{ textAlign: 'left', color: "red" }}>{sendStatus.msg}</Typography>
                                        </Grid>
                                    </Grid> : null}
                                </form>
                            )}
                        />
                    </Grid>
                </Grid>
                <Dialog scroll={"paper"} fullWidth={true} maxWidth={"lg"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent style={{ padding: "10px 25px 25px" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <IconButton aria-label="close" onClick={handleClose} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px", float: "right" }}>
                                    <img alt="X" style={{ height: '20px' }} src={X} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                {helpImg(open.case)}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Container>

        </React.Fragment>
    )
}

export default ApplicationForm