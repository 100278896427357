import { createTheme } from '@mui/material/styles'
import { plPL } from '@mui/material/locale'

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    margin: '20px 0',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    fontSize: '14px',
                    height: '40px',
                    lineHeight: '40px',
                    color: '#4C5058',
                    '&:hover': {
                        border: '1px solid #DDE3EE'
                    }

                },
                multiline: {
                    height: '190px',
                    lineHeight: 1.2,
                },
                notchedOutline: {
                    border: '1px solid #DDE3EE',
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    lineHeight: '40px',
                    top: '-15px'
                },
            },
        },
    },
    typography: {
        color: '#4C5058',
        body2: {
            marginTop: '20px'
        },
        body1:{
            fontSize: '0.875rem',
            // '@media (max-width:960px)': {
            //     fontSize: '22px',
            // },
            // '@media (max-width:480px)': {
            //     fontSize: '16px',
            //   },
        },
        h4: {
            fontSize: '32px',
            '@media (max-width:960px)': {
                fontSize: '22px',
            },
            '@media (max-width:480px)': {
                fontSize: '16px',
              },
        },
    },
    palette: {

    },
    margin: {
        marginLarge: '50px auto',
        marginMedium: '30px auto',
        marginSmall: '15px auto',
    },
    input: {
        marginBottom: '20px',
    },
}, plPL)



export default theme