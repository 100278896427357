import React, { useState } from 'react'
import { Container, Grid, Typography, Button } from '@mui/material'
import { dateConvert } from '../../functions'

const actualDate = new Date()

const ThankYouPage = ({ setStatus, status }) => {
    const [contentMsg, setContentMsg] = useState(JSON.parse(status.content))
    let noteDate = new Date(status.date)
    return (
        <React.Fragment>
            <Container style={{ marginTop: "30px" }} maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}><Typography variant='h5' style={{ textAlign: 'left' }}>Zgłoszenie zostało przyjęte.<br /><br /></Typography></Grid>
                    <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}>Dziękujemy za wysłanie formularza zgłoszenia serwisowego do Euros Energy! Chcielibyśmy poinformować, że Twoje zgłoszenie zostało odebrane i jest obecnie weryfikowane przez nasz zespół techniczny. W najbliższym czasie powinieneś otrzymać od nas kontakt w celu ustalenia szczegółów dotyczących naprawy lub rozwiązania problemu.<br /><br /></Typography></Grid>
                    <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Numer zgłoszenia: </strong>{status.id}/{actualDate.getFullYear()}</Typography></Grid>
                    {status && contentMsg ? <React.Fragment>
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Data zgłoszenia: </strong>{dateConvert(noteDate).toLocaleString()}</Typography></Grid>
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Imię i nazwisko: </strong>{contentMsg[0].first_name} {contentMsg[0].last_name}</Typography></Grid>
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Adres montażu: </strong>{contentMsg[0].street} {contentMsg[0].street_number}, {contentMsg[0].city} {contentMsg[0].post_code}</Typography></Grid>
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Tel.: </strong>{contentMsg[0].phone}</Typography></Grid>
                        {contentMsg[0].user_email ? <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>E-mail: </strong>{contentMsg[0].user_email}</Typography></Grid> : null}
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Numer seryjny: </strong>{status.device_sn}</Typography></Grid>
                        {contentMsg[0].outside_sn ? <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Numer jednostki zewnętrznej: </strong>{contentMsg[0].outside_sn}</Typography></Grid> : null}
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Klucz: </strong>{status.key}</Typography></Grid>
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Zgoda na kontakt: </strong>{contentMsg[0].confirmation}</Typography></Grid>
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Zgoda na badanie satysfakcji: </strong>{contentMsg[0].confirmation2}</Typography></Grid>
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left' }}><strong>Treść zgloszenia: </strong>{contentMsg[0].msg}</Typography></Grid>
                        </React.Fragment>
                        : null}
                        <Grid item xs={12}><Typography variant='body1' style={{ textAlign: 'left', marginTop: "20px" }}>Jeszcze raz dziękujemy za skorzystanie z usług Euros Energy i zapewniamy Cię, że zrobimy wszystko, co w naszej mocy, aby szybko i skutecznie rozwiązać Twój problem.</Typography></Grid>
                    <Grid item xs={12}><Button variant="contained" color="warning" style={{ float: 'right' }} onClick={() => setStatus({ status: false, data: null })}>Zamknij</Button></Grid>
                </Grid>

            </Container>
        </React.Fragment>
    )
}

export default ThankYouPage