const sendSmsPush = async (payload, key, phone, title) => {

    const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customer/send_sms_after_service_note`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...payload, phone: phone})
    }).then(response => {
      console.log({response})
    })
    .then(async response => {
          const response2 = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customer/send_push_after_service_note`, {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({...payload, key: key, title: title})
          }).then(response2 => console.log({response2}))

          return response2
  })

    return res
  }
  
  
  export default sendSmsPush